import Vue from "vue";
import Vuex from "vuex";
import EgauImg from "@/assets/img/tn/egau_tn.jpg";
import tbslImg from "@/assets/img/tn/tbsl-tn.jpg";
import nzImg from "@/assets/img/tn/jaru-nz.jpg";
import NBImg from "@/assets/img/tn/NilsBuri-thumbnail.jpg";
import friseImg from "@/assets/img/tn/frise-tn.jpg";
import chempaeImg from "@/assets/img/tn/chempae-tn.jpg";
import sdImg from "@/assets/img/tn/sd-tn.jpg";
import ozsImg from "@/assets/img/tn/ozs-tn2.jpg";
import mnImg  from "@/assets/img/tn/mn-tn.jpg";
import wmImg from "@/assets/img/tn/workmed-tn.jpg";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [
      {
        image: mnImg,
        title: "Mini Nachbere",
        rolls:[
          {title: "Musikvideo für", name: "<a target='_blank' href='https://sanmattia.ch'>san mattia<br><br>"},
          {title: " Regie & Schnitt:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>"},
          {title: "DoP:", name: "<a target='_blank' href='https://mauricebusch.ch'>Maurice Busch<a>"},
          {title: "AC:", name: "<a target='_blank' href='https://www.instagram.com/tinokistler/'>Tino Kistler</a>"},
          {title: "Sound & Title Font:", name: "Dan Mattia Mueri <br><br>"},
          {title: "Special Thanks:", name:"Chistian, Fabio & Startstutz"},
        ],
        description: 'Für den Berner Mundardmusiker san mattia habe ich das Musikvideo zu seiner Single "Mini Nachbere" geschrieben und Regie geführt. ',
        path: "mini-nachbere",
        video:"https://www.youtube-nocookie.com/embed/owPhi2NvCxk?si=UTjI_-CAk5DINcuY"
      },
      {
        image: wmImg,
        title: "Workmed",
        description: "Für ein Kampagnen Video für die Firma WorkMed AG, arbeitete ich an zwei Drehtagen als 1st AC und Grip.",
        rolls: [
          {title: "Kampagnenvideo für", name: "<a target='_blank' href='https://workmed.ch/'>WorkMed AG</a><br><br>"},
          {title: "Regie & DoP:", name: "<a target='_blank' href='https://mauricebusch.ch/contact.html'>Maurice Busch<a>"},
          {title: "1st AC & Grip:", name: "Andrin Schranz,<br>Fabio Gaiardo,<br>Jan Nyffenegger,<br><a href='/about'><hl>Jonas Kunz</hl></a><br>Tino Kistler"},

        ],
        path: "workmed",
        video: "https://www.youtube-nocookie.com/embed/gLhcHm6cizY?si=NPTascMoTZTEcaWt"
      },
      {
        image: ozsImg,
        title: "Opps zu spät",
        rolls:[
          {title: "Musikvideo für", name: "KanteK<br><br>"},
          {title: "Regie & Schnitt:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>"},
          {title: "Kamera:", name: "<a target='_blank' href='https://mauricebusch.ch/contact.html'>Maurice Busch<a><br><br>"},
          {title: "Mit:", name: "Lalo, Mikado & Felix Fuhrer"},
        ],
        description: 'Beim das Musikvideo zum Song "Opps zu spät" für KanteK erstellte ich das Konzept und führte Regie.',
        path: "ozs-kantek",
        video:"https://www.youtube-nocookie.com/embed/L-keiyMw0mY?si=Q2p53X6a9VT1aiVI"
      },
      {
        image: tbslImg,
        title: "the bittersweet life",
        rolls: [
          { title: "Dokumentarfilm für", name: "<a target='_blank' href='https://ocular.ch'>Ocular Collective<a>" },
          { title: "Mit:", name: "Ara Ko<br><br>" },
          { title: "Interview & Regie:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>" },
          {
            title: "Kamera:",
            name: "Andrin Schranz & Cedric Ribi <br><br>"
          },
          { title: "Lead Schnitt:", name: "Cedric Ribi" },
          { title: "Farben & Animation:", name: "Andrin Schranz" },
          { title: "Audio & Sound Design", name: "a href='/about'><hl>Jonas Kunz</hl></a>" }
        ],
        path: "tbsl",
        video: "https://www.youtube-nocookie.com/embed/MM9XUcJ1hBw?si=zD3VI3dlmserlZxX"
      },
      {
        image: EgauImg,
        title: "Egau",
        rolls: [
          { title: "Musikvideo für", name: "<a target='_blank' href='https://www.julexxx.com/'>Jule X </a> & Nicky B Fly <br><br>" },
          { title: "Regie & Schnitt:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>" },
          { title: "DoP & Colorist:", name: "Andrin Schranz" },
          { title: "Kamera:", name: "Cedric Ribi<br><br>" },
        ],
        path: "egau",
        video: "https://www.youtube-nocookie.com/embed/EE4Mr78Wh-E?si=2pzNjsBOX5H65Jm7"
      },
      {
        image: chempaeImg,
        title: "chempä.",
        rolls: [
          { title: "Kurzfilm für", name: "<a target='_blank' href='https://ocular.ch'>Ocular Collective<a>" },
          { title: "Mit:", name: "<a href='/about'><hl>Jonas Kunz</hl></a><br><br>" },
          { title: "Story:", name: "<a target='_blank' href='https://ocular.ch'>Ocular Collective<a>" },
          { title: "Regie:", name: "a href='/about'><hl>Jonas Kunz</hl></a>" },
          { title: "Cinematography:", name: "Andrin Schranz & Cedric Ribi" },
          { title: "PA:", name: "Luka Aeschlimann<br><br>" },
          { title: "Schnitt & Colorist:", name: "a href='/about'><hl>Jonas Kunz</hl></a>" },
        ],
        path: "chempae",
        video: "https://www.youtube-nocookie.com/embed/rdOYQB_9wZ4?si=5DmdJQr5g8GrbLMe"
      },
      {
        image: sdImg,
        title: "Slow Down!",
        rolls:[
          {title: "Musikvideo für", name: "<a target='_blank' href='https://locymusic.ch/'>LocY</a><br><br>"},
          {title: "Regie & Schnitt:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>"},
          {title: "Co-Regie:", name: "LocY & Minako Bisang"},
          {title: "DoP & Colorist:", name: "Andrin Schranz<br><br>"},
          {title: "1st AC:", name: "<a target='_blank' href='https://www.instagram.com/noelgygax/'>Noël Gygax</a><br><br>"},
          {title: "Animation:", name: "Minako Bisang & Ysabel Steiner"},
          {title: "Makeup:", name: "Sarah Walker"},
          {title: "PAs:", name: "Feride Saljmanaj, Jasmin Angelina & Minako Bisang"},
          {title: "Styling:", name: "Reya Amalia"}
        ],
        path: "slow-down",
        video: "https://www.youtube-nocookie.com/embed/3oSUMN9nUDw?si=ufxT7hL6aLAaWHtj"

      },
      {
        image: friseImg,
        title: "frisä",
        rolls: [
          { title: "Kurzfilm für", name: "<a target='_blank' href='https://ocular.ch'>Ocular Collective<a>" },
          { title: "Mit:", name: "Luka Aeschlimann<br><br>" },
          { title: "Regie:", name: "Ocular Collective" },
          { title: "Cinematographie:", name: "Andrin Schranz & Cedric Ribi" },
          { title: "PA:", name: "<a href='/about'><hl>Jonas Kunz</hl></a><br><br>" },
          { title: "Schnitt & Colorist:", name: "Andrin Schranz" },
        ],
        path: "frisae",
        video: "https://www.youtube-nocookie.com/embed/T3vn5nujkn8?si=FdnqdBZuF-Fb0KfS"
      },
      {
        image: nzImg,
        title: "nüt ztüe",
        rolls: [
          { title: "Musikvideo für", name: "Jaru <br><br>" },
          { title: "Regie & Schnitt:", name: "<a href='/about'><hl>Jonas Kunz</hl></a>" },
          { title: "Cinematographie:", name: "Andrin Schranz" },
          { title: "PA:", name: "Luis Schneuwly" }
        ],
        path: "nz",
        video: "https://www.youtube-nocookie.com/embed/a3eobSkWqfo?si=s7AbJDLqMFPTHJ2b"
      },
      {
        image: NBImg,
        title: "Nils Buri",
        rolls: [
          { title: "Dokumentarfilm für", name: "Nils Buri <br><br>" },
          { title: "Produktion:", name: "<a target='_blank' href='https://ocular.ch'>Ocular Collective<a>" }
        ],
        path: "nils-buri",
        video: "https://www.youtube-nocookie.com/embed/URoZHHvk4NQ?si=Es8SieUxba2Wqojt"
      }
    ],
    imprint: [
      {
        title: "Inhaltliche Verantwortung",
        content:
          '<strong>ocular KLG</strong><br>Kirchlindachstrasse 15<br>3052 Zollikofen<br><br><a href="mailto:info@ocular.ch">info@ocular.ch</a><br><br>CHE-411.013.552'
      },
      {
        subSections: [
          {
            title: "Copyright",
            content:
              "Die Urheber- und alle anderen Rechte an Inhalten wie Bilder, Illustrationen, Videos wie auch Texte oder andere Inhalte auf dieser Website, gehören ausschliesslich Jonas Kunz oder den speziell genannten Rechteinhabern. Für die Reproduktion jeglicher Inhalte ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen."
          }
        ],
        title: "Disclaimer",
        content:
          "Alle Texte und Links wurden sorgfältig geprüft und werden laufend aktualisiert. Jedoch übernimmt der Autor keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten Informationen, richtig, vollständig oder aktuell sind. Der Autor behält sich das Recht vor, jederzeit und ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichtet sich auch nicht, die enthaltenen Informationen zu aktualisieren. Alle Links zu externen Anbietern wurden zum Zeitpunkt ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haftet der Autor nicht für Inhalte und Verfügbarkeit von Websites, die mittels Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden direkter, indirekter oder finanzieller Natur ist oder ein sonstiger Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder anderen Gründen aller Art ergeben könnte.",
      },
      {
        title: "Datenschutz",
        content:
          "Für die Sicherheit der Datenübertragung im Internet können wir keine Gewähr übernehmen, insbesondere besteht bei der Übertragung von Daten per E-Mail die Gefahr des Zugriffs durch Dritte.<br>Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen. Der Betreiber behält sich für den Fall unverlangt zugesandter Werbe- oder Informationsmaterialien ausdrücklich rechtliche Schritte vor.<br>Sollten einzelne Regelungen oder Formulierungen dieses Haftungsausschlusses unwirksam sein oder werden, bleiben die übrigen Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.",
        subSections: [
          {
            title: "Adobe Fonts",
            content:
              'Wir setzen auf unserer Website Adobe Fonts zur visuellen Gestaltung ein. Adobe Fonts ist ein Dienst der Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe), der uns den Zugriff auf eine Schriftartenbibliothek gewährt. Zur Einbindung der von uns benutzten Schriftarten, muss dein Browser eine Verbindung zu einem Server von Adobe in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe erhält hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere Informationen zu Adobe Fonts, finden Sie in der Datenschutzerklärung von Adobe, welche du unter folgendem Link findest: <a href="https://www.adobe.com/ch_de/privacy/policies/adobe-fonts.html" target="_blank">Adobe Fonts Datenschutzerklärung</a>'
          }
        ]
      }
    ]
  },
  mutations: {},
  actions: {},
  modules: {}
});
